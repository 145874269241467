import React from "react";
import PropTypes from 'prop-types';
import { translateThis } from '../../../../helpers/language.helper';
import './approach.scss';
import S3ImageViewerWithFallback from "../../../../components/awsS3ImageViewer/ImgViewerWithFallback";




function Approach({facilityData}) {
  
  return (
    <>
      <div className="TemplatesOne-approach-wrp f-wrp">
        <div className="row">
          {facilityData.map((element, key) => (
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={key}>
              <div className="each-approach-sec f-wrp ">
                <div className="approach-con-sec f-wrp">
                  <span className='fee-sec-icon'>
                    <S3ImageViewerWithFallback key={`file-${element?.icon.fileKey}`} fileKey={element?.icon.fileKey} />
                  </span>
                  <h2>{translateThis(element.title)}</h2>
                  {element.description && <p>{translateThis(element.description)}</p>}
                </div>

                <div className="approach-basic-sec f-wrp">
                  <div className='trip-grid'>
                    <div className='age'>
                      <span><b>{translateThis('Age')}:</b>{translateThis(element.ageLimit)}</span>
                    </div>
                    <div className='days'>
                      <span><b>{translateThis('Days')}:</b>{translateThis(element.workingDays)}</span>
                    </div>
                    <div className='hours'>
                      <span><b>{translateThis('Hours')}:</b>{translateThis(element.workingHours)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
Approach.propTypes = {
  facilityData: PropTypes.any
}
export default Approach;