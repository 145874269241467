/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVGIcons from '../images/icons/svgIcons';

import './gallery.scss';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';


function Gallery({ facilityData }) {

  // const slideCount
  const breakpoints = {
    0: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
  };

  const slidesPerView = {
    0: breakpoints[0].slidesPerView,
    640: breakpoints[640].slidesPerView,
    768: breakpoints[768].slidesPerView,
    1024: breakpoints[1024].slidesPerView,
  };

  const [currentSlidesPerView, setCurrentSlidesPerView] = useState(slidesPerView[0]);

  const updateSlidesPerView = () => {
    const width = window.innerWidth;
    if (width < 640) {
      setCurrentSlidesPerView(slidesPerView[0]);
    } else if (width < 768) {
      setCurrentSlidesPerView(slidesPerView[640]);
    } else if (width < 1024) {
      setCurrentSlidesPerView(slidesPerView[768]);
    } else {
      setCurrentSlidesPerView(slidesPerView[1024]);
    }
  };

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener('resize', updateSlidesPerView);

    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);

  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.SlideLeft />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.SlideRight />
    </button>
  );
  const swiperRef = useRef(null);
  // to increase slide count for smooth navigation
  const requiredSlides = currentSlidesPerView * 2;
  const adjustedSlidesData = facilityData.length < requiredSlides
    ? [...facilityData, ...facilityData]
    : facilityData;


  const [index, setIndex] = useState(-1);
  const [selectedPhoto, setSelectedPhoto] = useState([]);

  return (
    <>
      <div className="gallery-sec-wrp TemplatesOne f-wrp">

        <Swiper
          onSwiper={(swiper) => { swiperRef.current = swiper; }}
          modules={[Navigation, Autoplay]}
          spaceBetween={10}
          slidesPerView={3}
          className='gallery-sec'
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          loop
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: slidesPerView[0],
              spaceBetween: breakpoints[0].spaceBetween,
            },
            640: {
              slidesPerView: slidesPerView[640],
              spaceBetween: breakpoints[640].spaceBetween,
            },
            768: {
              slidesPerView: slidesPerView[768],
              spaceBetween: breakpoints[768].spaceBetween,
            },
            1024: {
              slidesPerView: slidesPerView[1024],
              spaceBetween: breakpoints[1024].spaceBetween,
            },
          }}

        >
          {adjustedSlidesData.map((i, k) => (
            <SwiperSlide key={`${i.fileKey}-${k}`} >
              <span className='gallery-image' style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedPhoto(adjustedSlidesData.map((i) => ({ src: i.fileKey })))
                  setIndex(k)
                }}>
                <S3ImageViewerWithFallback key={`file-${i.fileKey}`} fileKey={i.fileKey} />
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
        <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
        <CustomNextButton onClick={() => swiperRef.current.slideNext()} />
      </div>

      {selectedPhoto && <Lightbox
        slides={selectedPhoto}
        render={{
          slide: ({ slide }) => <S3ImageViewerWithFallback isShowRetry key={`file-${slide.src}`} fileKey={slide.src} />,
        }}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen,
          Slideshow,
          // Thumbnails,
          Zoom]}
        controller={{ closeOnBackdropClick: 'true' }}
      />}

    </>
  );
}
Gallery.propTypes = {
  facilityData: PropTypes.any
}
export default Gallery;