import * as React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Logout } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import SVGIcons from '../../../assets/images/icons/svgIcons';

import './index.scss';
import { isLoggedIn } from '../../../helpers/auth.helper';
import LogoutButton from '../../buttons/logout.button';
import { translateThis } from '../../../helpers/language.helper';

import { myPortalState } from '../../../state/reducerAtoms/myPortal.atom';


export default function BurgerMenu() {
  const isClientLoggedIn = isLoggedIn();
  const myPortalInitialState = useRecoilValue(myPortalState);
  const { facilityInfo = {} } = myPortalInitialState;
  const { features = {} } = facilityInfo;

  const mobNavItems = [

  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  /**
   * Handles the submenu click
   * @param {Event} event 
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the submenu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className='f-wrp'>
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className='burger-icon'
        >

          {SVGIcons.BurgerMenuIcon()} &nbsp;&nbsp; {SVGIcons.ProfileIcon()}

        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          className='menu-main-wrp'
        >
          {(!isClientLoggedIn && features?.parentRegistration) ?
            <MenuItem className='each-menuItem'>
              <Link to={`auth/register`}>
                <Button onClick={handleClose}>
                  <span className='menu-icon'>{SVGIcons.ProfileIcon()}</span>
                  <span>{translateThis('Register')}</span>
                </Button>
              </Link>
            </MenuItem> : null}
          {!isClientLoggedIn && <MenuItem className='each-menuItem'>
            <Link to={'auth/login'}>
              <Button onClick={handleClose}>
                <span className='menu-icon'>{SVGIcons.ProfileIcon()}</span>
                <span>{translateThis('Login')}</span>
              </Button>
            </Link>
          </MenuItem>}
          {!isClientLoggedIn &&
            <Divider />}
          {mobNavItems.filter((item) => !item.isHidden).map((element) => (
            <MenuItem key={element.title} className='each-menuItem'>
              <Link to={`${element.link}`} style={{ width: '100%' }} >
                <Button sx={{ textAlign: 'center' }} onClick={handleClose}>
                  <span className='menu-icon'><img src={element.icon} alt={element.title} /></span>
                  <span>{element.title}</span>
                </Button>
              </Link>
            </MenuItem>
          ))}
          {isClientLoggedIn && <Divider />}
          {isClientLoggedIn && <MenuItem className='each-menuItem' >
            <Link>
              <Button>
                <span className='menu-icon'><Logout /></span>
                <LogoutButton />
              </Button>
            </Link>
          </MenuItem>}
        </Menu>
      </div>
    </>

  )

}