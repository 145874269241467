import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import AppIcons from '../../assets/images/icons/index';
import AppLayout from '../../layouts/app/AppLayout';
import RegisterForm from './forms/register.form';

import './auth.scss';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';

const RegisterPage = () => {
    const pageName = 'Register';
    const navigate = useNavigate();
    const {facilityInfo = {}} = useRecoilValue(myPortalState);
    const currentFacilityURL = getCurrentFacilityURL();
    const { features = {} } = facilityInfo;

    /**
     * To check if user registration is available for this facility
     */
    useEffect(()=> {
        if (!features?.parentRegistration) {
            navigate(-1, { replace: true });
        }
        // eslint-disable-next-line
    }, [features])
    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            pageName={pageName}
            bodyClassName='Signup-wrp auth-layout'
        >
            <div className='auth-page-layout f-wrp'>
                <div className='container-fluid'>
                    <div className='flex-box'>
                        <div className='form-sec-wrp'>
                        <Link to={`/${currentFacilityURL}`} className='auth-logo'><img src={facilityInfo?.facilityLogo?.tempURL || AppIcons.logo} alt='logo' /> </Link>
                            <div className='auth-form'>
                                <Stack className='form-tile'>
                                    <Typography variant="h3" fontStyle={'normal'} fontWeight={'400'}>Get started now</Typography>
                                    <Typography variant="h4" fontStyle={'normal'} fontWeight={'400'}>Enter your credentials</Typography>
                                </Stack>
                                <RegisterForm />
                            </div>
                        </div>
                        <div className='form-img-wrp'>
                            <span><img src={AppIcons.registerBG} alt='register' /></span>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default RegisterPage;