import React from "react";
import PropTypes from 'prop-types';
import { translateThis } from '../../../../helpers/language.helper';
import './programs.scss';
import IconViewer from "../../../../components/iconPicker/icon-viewer";


function Programs({ programsArr = [] }) {


  // to divide the list array to 4 segment to fulfill the UI
  const programsFormatted = splitIntoSubPrograms(programsArr);


  // function splitIntoSubPrograms(programsArr, chunkSize = 4) {
  //   const result = [];
  //     for (let i = 0; i < programsArr.length; i += chunkSize) {
  //         result.push(programsArr.slice(i, i + chunkSize));
  //     }
  //     return result;
  // }

  function splitIntoSubPrograms(programsArr) {
    const result = [];
    const chunkSize = Math.ceil(programsArr.length / 4);  // Calculate the size of each chunk

    for (let i = 0; i < programsArr.length; i += chunkSize) {
      result.push(programsArr.slice(i, i + chunkSize));  // Push the chunk into the result
    }

    // If the length of the array is less than 4, it will handle by just splitting based on the calculated chunkSize
    return result;
  }


  return (
    <>
      <div className="TemplatesOne-program-wrp f-wrp">
        <div className="row">
          {programsFormatted.map((element, key) => (
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12" key={key}>
              <div className={`each-program-sec program-${key} f-wrp `}>
                {element.length > 0 && <ul>
                  {element.map((programItem, id) => (
                    <li key={programItem.id || id}>
                      <span className='program-icon'>
                        {/* {programItem.icon} */}
                        <IconViewer icon={programItem.icon} />
                      </span>
                      <p>{translateThis(programItem.title)}</p>
                    </li>
                  ))}
                </ul>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
Programs.propTypes = {
  programsArr: PropTypes.any
}
export default Programs;