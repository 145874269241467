import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVGIcons from '../images/icons/svgIcons';
import './Services.scss';
import { translateThis } from "../../../../helpers/language.helper";
import IconViewer from '../../../../components/iconPicker/icon-viewer';


function FriendlyFamilyServices({ services }) {
  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.SlideLeft />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.SlideRight />
    </button>
  );
  const swiperRef = useRef(null);
  const [navigationEnabled, setNavigationEnabled] = useState(false);
  const [slideCount, setSlideCount] = useState(0);
// to add slide count based on screen size
  useEffect(() => {
    setTimeout(() => {

      if (services.length === 1) {
        setSlideCount(1);
        setNavigationEnabled(false);
      } else if (services.length === 2) {
        setSlideCount(2);
        setNavigationEnabled(false);
      } else if (services.length === 3) {
        setSlideCount(3);
        setNavigationEnabled(false);
      } else {
        setSlideCount(3);
        setNavigationEnabled(true);
      }
      const handleResize = () => {
        const width = window.innerWidth;
        if (width < 767) {
          if (services.length === 1) {
            setSlideCount(1);
            setNavigationEnabled(false);
          } else if (services.length === 2) {
            setSlideCount(2);
            setNavigationEnabled(false);
          } else if (services.length === 3) {
            setSlideCount(2);
            setNavigationEnabled(true);
          } else {
            setSlideCount(2);
            setNavigationEnabled(true);
          }
        }
        // eslint-disable-next-line yoda
        if (0 < width && width < 520) {
          if (services.length === 1) {
            setSlideCount(1);
            setNavigationEnabled(false);
          } else {
            setSlideCount(1);
            setNavigationEnabled(true);
          }
        }

      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };

    }, 500);
    // eslint-disable-next-line
  }, []);

// to add class name on each slides to do dynamic color change

  const classNames = ['service1', 'service2', 'service3', 'service4'];
 
  const servicesWithClasses = services.map((service, index) => {
    const className = classNames[index % classNames.length]; 
    return { service, className };
  });
// to increase slide count for smooth navigation
  const requiredSlides = slideCount * 2;
  const adjustedSlidesData = servicesWithClasses.length < requiredSlides
    ? [...servicesWithClasses, ...servicesWithClasses]
    : servicesWithClasses;
  
  return (
    <>
      <div className="TemplatesOne-Services-wrp">
        <Swiper
          onSwiper={(swiper) => { swiperRef.current = swiper; }}
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={slideCount}
          className='content-sec-mob'
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
              navigation: true,
              autoplay: true,
            },
            640: {
              slidesPerView: slideCount,
              spaceBetween: 20,
              navigation: true,
              autoplay: true,
            },
            768: {
              slidesPerView: slideCount,
              spaceBetween: 20,
              navigation: false,
              autoplay: true,
            },
            1024: {
              slidesPerView: slideCount,
              spaceBetween: 20,
              navigation: false,
            },
            1275: {
              slidesPerView: slideCount,
              spaceBetween: 10,
              navigation: false,
              autoplay: false,
            },
          }}

        >
          {adjustedSlidesData.map((element, key) => (
           <SwiperSlide key={key} className={`${element.className}`}>
                <div className={`each-service-sec f-wrp`}>
                  <span className='service-icon'><IconViewer icon={element.service.icon} /></span>
                  <div className="service-con-sec f-wrp">
                    <h2>{translateThis(element.service.title)}</h2>
                    <p>{translateThis(element.service.shortDescription)}</p>
                  </div>
                </div>
              </SwiperSlide>
          ))}
          {navigationEnabled && <>
            <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
            <CustomNextButton onClick={() => swiperRef.current.slideNext()} /> </>}
        </Swiper>
      </div>
    </>
  );
}
FriendlyFamilyServices.propTypes = {
  services: PropTypes.any
}
export default FriendlyFamilyServices;