import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import propTypes from 'prop-types';

// @mui
import { Stack, Grid, Button, Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import Checkbox from '@mui/material/Checkbox';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// Helpers
import { getAuthDetailsByKey, getAuthId } from '../../../../helpers/auth.helper';

// services
import { getChildLogBy, submitDailyLog } from '../../../../services/api/dailyLogs';
import { getChild } from '../../../../services/api/child';

// Components
import { showSnackbar } from '../../../../components/snackbar/index';

import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_EXTENDED_FORMAT, TIME_12HR_FORMAT } from '../../../../constants/index';
import { ParentCommunicationFormDefaultValues } from '../models/parentCommunication.model';
import { translateThis } from '../../../../helpers/language.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { getUniqueId } from '../../../../helpers/helper.functions';
import TimeInput from '../../../../components/inputs/timeInput';



const ParentCommunicationForm = (props) => {

    const pageName = "Parent communication";
    const logKey = "parent-communication";

    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const providerId = getAuthDetailsByKey('providerId');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [logDate, setLogDate] = useState(dayjs());
    const [submissionDate, setSubmissionDate] = useState(dayjs());
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [initialFormData, setInitialFormData] = useState(ParentCommunicationFormDefaultValues);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(logKey))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        setInterval(() => {
            setSubmissionDate(dayjs())
        }, 1000);
        return () => {
            setInitialFormData(ParentCommunicationFormDefaultValues)
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildLogBy(parentId, childId, logKey)
            .then((data) => {
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                }               
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (formData.wakeUpTime && signature && childId && isSigned) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        let formDataAs = formData.concat(ParentCommunicationFormDefaultValues)
        formDataAs = formDataAs.filter((v, i, a) => a.findIndex(v2 => ['questionId', 'type'].every(k => v2[k] === v[k])) === i)
        const postData = {
            logKey: 'parent-communication',
            logTitle: 'Parent Communication',
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT)
            },
            parentId,
            providerId,
            childId,
            formData: formDataAs,
            isParentSigned: true,
            submissionDate: dayjs().format(DATE_TIME_FORMAT),
            logDate
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitDailyLog(postData).then((data) => {
                if (data.success) {                    
                    showSnackbar().success(data.message);
                    onChangeFormState();
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }
    };
    return (

        <div key={formKey} className='panel-form-sec blk-bg f-wrp'>
            {isLoading ? <span>Loading...</span> : <div className='form-detail-sec f-wrp'>
                <div className='general-info-sec f-wrp'>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <h2>{translateThis(pageName)}</h2>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <h3>{translateThis("Parent communication")}</h3>
                            </div>
                        </Grid>

                        {(selectedChild) && <Grid item xs={12} sm={6} md={6}>
                            <Stack className='panel-form padding-top-10px' spacing={3}>
                                <TextField
                                    type='text'
                                    disabled
                                    defaultValue={`${selectedChild?.firstName} ${selectedChild?.lastName}`}
                                    name="childName"
                                    label={translateThis("Child’s name")}
                                />
                            </Stack>
                        </Grid>}
                        <Grid item xs={12} sm={6} md={3}>
                            <Stack className='panel-form calender-sec' spacing={3}>
                                <LocalizationProvider className="custom-textfield" dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            format={DATE_FORMAT}
                                            label={translateThis("Log date")}
                                            defaultValue={logDate}
                                            maxDate={dayjs()}
                                            disableFuture
                                            onChange={(v) => setLogDate(dayjs(v).format(DATE_FORMAT))}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Stack>
                        </Grid>
                        {submissionDate && <Grid item xs={12} sm={6} md={3}>
                            <Stack className='panel-form calender-sec' spacing={3}>
                                <TextField
                                    type='text'
                                    label={translateThis("Submission date and time")}
                                    value={dayjs(submissionDate).format(DATE_TIME_EXTENDED_FORMAT)}
                                    disabled
                                />

                            </Stack>
                        </Grid>}

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span>{translateThis("Any incidents at home after daycare")} <i> {translateThis("if not leave empty")} </i></span>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form' spacing={3}>
                                <TextField
                                    label={translateThis('incidentsAtHome')}
                                    name='incidentsAtHome'
                                    id='incidentsAtHome'
                                    defaultValue={answerGetter('incidentsAtHome', initialFormData)?.answer}
                                    type='text'
                                    rows={4}
                                    multiline
                                    placeholder='Please enter'
                                    className='custom-textfield'
                                    onChange={(e) => {
                                        setFormValue({
                                            questionId: 'incidentsAtHome',
                                            answer: e.target.value,
                                            translationKey: 'incidentsAtHome',
                                            type: 'text'
                                        })
                                    }}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <FormControl>
                                    <FormLabel id="food">{translateThis('food')}</FormLabel>
                                    <RadioGroup row
                                        aria-labelledby="food"
                                        defaultValue={answerGetter('food', initialFormData)?.answer}
                                        name="food"
                                        onChange={(e) => {
                                            setFormValue({
                                                questionId: 'food',
                                                answer: e.target.value,
                                                translationKey: 'food',
                                                type: 'radio'
                                            })
                                        }}
                                    >
                                        <FormControlLabel value="Bottle | Nursed" control={<Radio />} label={translateThis('Bottle Nursed')} />
                                        <FormControlLabel value="Did not eat" control={<Radio />} label={translateThis('Did not eat')} />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <FormControl>
                                    <FormLabel id="diaperChange">{translateThis('diaperChange')}</FormLabel>
                                    <RadioGroup row
                                        aria-labelledby="diaperChange"
                                        defaultValue={answerGetter('diaperChange', initialFormData)?.answer}
                                        name="diaperChange"
                                        onChange={(e) => {
                                            setFormValue({
                                                questionId: 'diaperChange',
                                                answer: e.target.value,
                                                translationKey: 'diaperChange',
                                                type: 'radio'
                                            })
                                        }}
                                    >
                                        <FormControlLabel value="Dry" control={<Radio />} label={translateThis('Dry')} />
                                        <FormControlLabel value="Wet" control={<Radio />} label={translateThis('Wet')} />
                                        <FormControlLabel value="BM" control={<Radio />} label={translateThis('BM')} />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <FormControl>
                                    <FormLabel id="mood">{translateThis('mood')}</FormLabel>
                                    <RadioGroup row
                                        aria-labelledby="mood"
                                        defaultValue={answerGetter('mood', initialFormData)?.answer}
                                        name="mood"
                                        onChange={(e) => {
                                            setFormValue({
                                                questionId: 'mood',
                                                answer: e.target.value,
                                                translationKey: 'mood',
                                                type: 'radio'
                                            })
                                        }}
                                    >
                                        <FormControlLabel value="Normal" control={<Radio />} label={translateThis("Normal")} />
                                        <FormControlLabel value="Tired | Fussy" control={<Radio />} label={translateThis("Tired Fussy")} />
                                        <FormControlLabel value="Unwell" control={<Radio />} label={translateThis("Unwell")} />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form box-class-form' spacing={3}>
                                <FormControl>
                                    <FormLabel id="natureOfSleep">{translateThis('natureOfSleep')}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="natureOfSleep"
                                        name="natureOfSleep"
                                        defaultValue={answerGetter('natureOfSleep', initialFormData)?.answer}
                                        onChange={(e) => {
                                            setFormValue({
                                                questionId: 'natureOfSleep',
                                                answer: e.target.value,
                                                translationKey: 'natureOfSleep',
                                                type: 'radio'
                                            })
                                        }}
                                    >
                                        <div>
                                            <TimeInput
                                                defaultValue={dayjs(answerGetter(`wakeUpTime`, initialFormData)?.answer,TIME_12HR_FORMAT )}
                                                label={translateThis('wakeUpTime')}
                                                onChange={(v) => {
                                                    setFormValue({
                                                        questionId: `wakeUpTime`,
                                                        answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                        translationKey: `wakeUpTime`,
                                                        type: 'text'
                                                    })
                                                }}
                                            />

                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <FormControlLabel value="Slept soundly" control={<Radio />} label={translateThis("Slept soundly")} />
                                        <FormControlLabel value="Did not sleep" control={<Radio />} label={translateThis("Did not sleep")} />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span>{translateThis('Comments')} <i> ({translateThis('optional')})</i></span>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form' spacing={3}>
                                <TextField
                                    label={translateThis('comments')}
                                    name='comments'
                                    id='comments'
                                    defaultValue={answerGetter('comments', initialFormData)?.answer}
                                    type='text'
                                    placeholder={translateThis('comments')}
                                    rows={4}
                                    multiline
                                    className='custom-textfield'
                                    onChange={(e) => {
                                        setFormValue({
                                            questionId: 'comments',
                                            answer: e.target.value,
                                            translationKey: 'comments',
                                            type: 'text'
                                        })
                                    }}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Divider style={{ maxWidth: '100%' }} />
                        </Grid>


                        <Grid item xs={12} sm={6} md={6}>
                            <Divider style={{ display: 'none' }} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>


                            <Stack className='panel-form' spacing={3}>
                                <div className='info-head f-wrp'>
                                    <span>{translateThis("Parent Guardian signature")}</span>
                                </div>
                                <TextField
                                    label={translateThis('typeYourName')}
                                    name='signature'
                                    id='signature'
                                    type='text'
                                    required
                                    placeholder='Name'
                                    className='custom-textfield'
                                    style={{ maxWidth: '200px' }}
                                    onChange={(e) => {
                                        setFormValue({
                                            questionId: 'signature',
                                            answer: e.target.value,
                                            translationKey: 'signature',
                                            type: 'text'
                                        })
                                        setSignature(e.target.value)
                                    }}
                                />

                                <FormGroup className='check-label'>
                                    <FormControlLabel
                                        defaultChecked={isSigned}
                                        onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("I")}, {(signature) && <b>{signature},</b>} {translateThis("affirm that the information")}</span>
                                        } />
                                </FormGroup>
                            </Stack>
                        </Grid>

                    </Grid>
                </div>
                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button type='button' onClick={onChangeFormState} className='cancel-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        {/* <Button onClick={() => navigator('/')} className='cancel-btn'>Upload</Button> */}
                        {/* <Button onClick={() => navigator('/')} className='pln-btn'>Download</Button> */}
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isSigned || !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis("submit")}
                        </LoadingButton>
                    </div>
                </div>
            </div>}
        </div>

    )
}
ParentCommunicationForm.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}
export default ParentCommunicationForm;