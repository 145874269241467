import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { NavHashLink } from 'react-router-hash-link';
import Modal from '@mui/material/Modal';
import Marquee from "react-fast-marquee";
import S3ImageViewerWithFallback from '../../../components/awsS3ImageViewer/ImgViewerWithFallback';
import { getFileByKey } from '../../../services/api/aws';
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import FriendlyFamilyServices from './Services/Services';
import './custom.scss';
import { translateThis } from '../../../helpers/language.helper';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import Programs from './programs/programs';
import SVGIcons from './images/icons/svgIcons';
import Teachers from './teachers/teachers';
import Approach from './approach/approach';

import ContactForm from './contactForm/contactForm';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';


const TemplatesOne = ({ facilityData, language = 'en' }) => {
    const websiteData = language === 'es' ? facilityData?.websiteEs : facilityData?.website;

    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('TemplateOne');
            document.body.classList.add(`${websiteData?.colorPattern}`);

        }, 50);
        // eslint-disable-next-line
        getFacilityFavIcon()
        // eslint-disable-next-line
    }, []);
    const currentFacilityURL = getCurrentFacilityURL();

    const routineItems = [
        {
            icon: SVGIcons.FriendlyHours(),
            title: translateThis('routineHours'),
            content: `${websiteData?.workHours}`,
        },
        {
            icon: SVGIcons.FriendlyAge(),
            title: translateThis('routineAccepted ages'),
            content: `${websiteData?.workAcceptedAges}`,
        },
        {
            icon: SVGIcons.FriendlyDays(),
            title: translateThis('routineDays'),
            content: `${websiteData?.workDays}`,
        },
        {
            icon: SVGIcons.FriendlySubsidy(),
            title: translateThis('routineSubsidy programs'),
            content: `${websiteData?.workSubsidyPrograms}`,
        },

    ];

    const [openContactForm, setOpenContactForm] = useState(false);
    const [openTourForm, setOpenTourForm] = useState(false);
    const [favicon, setFavicon] = useState('');

    const handleOpenContactForm = () => {
        setOpenContactForm(true);
    };
    const handleCloseContactForm = () => {
        setOpenContactForm(false);
    };
    const handleOpenTourForm = () => {
        setOpenTourForm(true);
    };
    const handleCloseTourForm = () => {
        setOpenTourForm(false);
    };

    const getFacilityFavIcon = () => {
        if (websiteData?.favIcon) {
            getFileByKey(websiteData?.favIcon).then(({ data }) => {
                setFavicon(data?.imgURL || '')
            })
        }

    }

   
    // if (websiteData?.bannerButton1URL) {

    //     const bannerButton1URL = websiteData?.bannerButton1URL;
    //     const isExternalBannerButton1URL = bannerButton1URL.startsWith('http');
    //     const isHashLinkBannerButton1URL = bannerButton1URL.startsWith('#');
    //     const isInternalBannerButton1URL = bannerButton1URL.startsWith('/');

    // }

    // if (websiteData?.bannerButton2URL) {
    //     const bannerButton2URL = websiteData?.bannerButton2URL;
    //     const isExternalBannerButton2URL = bannerButton2URL.startsWith('http');
    //     const isHashLinkBannerButton2URL = bannerButton2URL.startsWith('#');
    //     const isInternalBannerButton2URL = bannerButton2URL.startsWith('/');
    // }

    const isExternalBannerButton1URL = websiteData?.bannerButton1URL?.startsWith('http');
    const isHashLinkBannerButton1URL = websiteData?.bannerButton1URL?.startsWith('#');
    const isInternalBannerButton1URL = websiteData?.bannerButton1URL?.startsWith('/');

    const isExternalBannerButton2URL = websiteData?.bannerButton2URL?.startsWith('http');
    const isHashLinkBannerButton2URL = websiteData?.bannerButton2URL?.startsWith('#');
    const isInternalBannerButton2URL = websiteData?.bannerButton2URL?.startsWith('/');




    const pageName = `${websiteData?.websiteTitle}`;
    return (
        <AppLayout
            pageName={pageName}
            favicon={favicon}
            bodyClassName='TemplatesOne'
            customHeader={<AppHeader facilityData={facilityData} />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp" >

                <div className='main-banner-wrp f-wrp' id={`${websiteData?.bannerId ? websiteData?.bannerId : ''}`}>
                    <div className='bannerBG'>
                        <div className='scroll-top f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollTop} alt='banner' /></span>
                            </Marquee>
                        </div>

                        <span className='pcBG'><S3ImageViewerWithFallback key={`file-${websiteData?.bannerImage}`} fileKey={websiteData?.bannerImage} /></span>
                        <span className='mobBG'><S3ImageViewerWithFallback key={`file-${websiteData?.bannerImage}`} fileKey={websiteData?.bannerImage} /></span>
                        <div className='scroll-bottom f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollBottom} alt='banner' /></span>
                            </Marquee>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <div className='banner-con'>
                                        <span><S3ImageViewerWithFallback key={`file-${facilityData?.facilityLogo?.fileKey}`} fileKey={facilityData?.facilityLogo?.fileKey} /></span>
                                        <h1>{websiteData?.bannerTitle}</h1>
                                        <p>{websiteData?.bannerShortDescription}</p>
                                    </div>
                                </div>

                                <div className='banner-btn-sec f-wrp'>
                                    {websiteData?.bannerButtonName1 ? <>
                                        {isInternalBannerButton1URL ? (
                                            <Button onClick={() => {
                                                window.location.href = `/${currentFacilityURL}${websiteData?.bannerButton1URL}`
                                            }} className='sqr-btn'>{websiteData?.bannerButtonName1}</Button>
                                        ) : isHashLinkBannerButton1URL ? (
                                            <NavHashLink smooth to={`${websiteData?.bannerButton1URL}`} >
                                                <Button className='sqr-btn'>
                                                    {websiteData?.bannerButtonName1}
                                                </Button>
                                            </NavHashLink>
                                        ) : isExternalBannerButton1URL ? (
                                            <Button onClick={() => {
                                                window.open(`${websiteData?.bannerButton1URL}`, '_blank')
                                            }} className='sqr-btn'>{websiteData?.bannerButtonName1}</Button>
                                        ) : <Button className='sqr-btn' onClick={handleOpenContactForm} >{websiteData?.bannerButtonName1}</Button>}
                                    </> : null}

                                    {websiteData?.bannerButtonName2 ? <>
                                        {isInternalBannerButton2URL ? (
                                            <Button onClick={() => {
                                                window.location.href = `/${currentFacilityURL}${websiteData?.bannerButton2URL}`
                                            }} className='sqr-btn child2'>{websiteData?.bannerButtonName2}</Button>
                                        ) : isHashLinkBannerButton2URL ? (
                                            <NavHashLink smooth to={`${websiteData?.bannerButton2URL}`} >
                                                <Button className='sqr-btn child2'>
                                                    {websiteData?.bannerButtonName2}
                                                </Button>
                                            </NavHashLink>
                                        ) : isExternalBannerButton2URL ? (
                                            <Button onClick={() => {
                                                window.open(`${websiteData?.bannerButton2URL}`, '_blank')
                                            }} className='sqr-btn child2'>{websiteData?.bannerButtonName2}</Button>
                                        ) : <Button className='sqr-btn child2' onClick={handleOpenContactForm} >{websiteData?.bannerButtonName2}</Button>}
                                    </> : null}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                {(websiteData?.serviceSectionTitle) ||
                    (websiteData?.serviceSectionShortDescription) ||
                    (websiteData?.services?.length > 0) ? <>
                    <div className='service-slider-sec f-wrp' id={`${websiteData?.serviceId ? websiteData?.serviceId : ''}`}>
                        <div className='service-sec-header f-wrp'>
                            <div className='container'>
                                <h1>{websiteData?.serviceSectionTitle}</h1>
                                <p>{websiteData?.serviceSectionShortDescription}</p>
                            </div>
                        </div>
                        <FriendlyFamilyServices services={websiteData?.services || []} />
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} /> </> : null}

                {(websiteData?.workBlockTitle) ||
                    (websiteData?.workBlockShortDescription) ||
                    (websiteData?.workBlockDescription) ? <>
                    <div className='about-section-wrp f-wrp' id={`${websiteData?.workId ? websiteData?.workId : ''}`}>
                        <div className='container'>
                            <div className='about-grid-wrp f-wrp'>
                                <div className='about-con-sec'>
                                    <h1>{websiteData?.workBlockTitle}</h1>
                                    <p>{websiteData?.workBlockShortDescription}</p>
                                    <p>{websiteData?.workBlockDescription}</p>
                                </div>
                                <div className='about-img-sec'>
                                    <div className='about-main-img'>
                                        <span><S3ImageViewerWithFallback key={`file-${websiteData?.workImageOne}`} fileKey={websiteData?.workImageOne} /></span>
                                    </div>

                                    <div className='about-img-subset'>
                                        <span><S3ImageViewerWithFallback key={`file-${websiteData?.workImageTwo}`} fileKey={websiteData?.workImageTwo} /></span>
                                        <span><S3ImageViewerWithFallback key={`file-${websiteData?.workImageThree}`} fileKey={websiteData?.workImageThree} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} /> </> : null}

                {(websiteData?.workHours) ||
                    (websiteData?.workDays) ||
                    (websiteData?.workSubsidyPrograms) ||
                    (websiteData?.workAcceptedAges) ? <>
                    <div className='info-wrapper-section f-wrp'>
                        <div className='routine-box f-wrp'>
                            <ul>
                                {routineItems.map((element, key) => (
                                    <Fragment key={key}>
                                        {element.content ? <li>
                                            <div className='each-routine-box f-wrp'>
                                                <div className='icon-sec'>
                                                    <span>{element.icon}</span>
                                                </div>
                                                <div className='content-sec'>
                                                    <h3>{element.title}</h3>
                                                    <p>{element.content}</p>

                                                </div>
                                            </div>
                                        </li> : null}
                                    </Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}

                {websiteData?.programs?.length > 0 ? <>
                    <div className='programs-slider-sec f-wrp' id={`${websiteData?.programBlockId ? websiteData?.programBlockId : ''}`}>
                        <div className='container'>
                            <div className='programs-sec-header f-wrp'>
                                <h1>{websiteData?.programBlockTitle}</h1>
                                <p>{websiteData?.programBlockShortDescription}</p>
                            </div>
                            <div className='gap f-wrp' style={{ paddingTop: '75px' }} />
                            <Programs programsArr={websiteData?.programs || []} />
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}



                {websiteData?.galleryImages?.length > 0 ? <>
                    <div className='gallery-section-wrp f-wrp' id={`${websiteData?.galleryBlockId ? websiteData?.galleryBlockId : ''}`}>
                        <div className='container-fluid'>
                            <div className='gallery-sec-header f-wrp'>
                                <h1>{websiteData?.galleryBlockTitle}</h1>
                                <p>{websiteData?.galleryBlockShortDescription}</p>
                                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                            </div>
                            <Gallery facilityData={websiteData?.galleryImages || []} />
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}



                {(websiteData?.installationBlockButtonTitle) ||
                    (websiteData?.installationBlockDescription) ||
                    (websiteData?.installationBlockTitle) ||
                    (websiteData?.installationImage) ? <>
                    <div className='install-blk-wrp f-wrp' id={`${websiteData?.installationBlockId ? websiteData?.installationBlockId : ''}`}>
                        <div className='container'>
                            <div className='install-sec-blk f-wrp'>
                                {websiteData?.installationImage ? <div className='install-img'>
                                    <span><S3ImageViewerWithFallback key={`file-${websiteData?.installationImage}`} fileKey={websiteData?.installationImage} /></span>
                                </div> : null}
                                <div className='install-con'>
                                    <div className='f-wrp'>
                                        {websiteData?.installationBlockButtonTitle ? <h1>{websiteData?.installationBlockTitle}</h1> : null}
                                        {websiteData?.installationBlockDescription ? <p>{websiteData?.installationBlockDescription}</p> : null}

                                        {/* <NavHashLink smooth to={'#getInTouch'} > */}
                                        {websiteData?.installationBlockButtonTitle ? <Button className='sqr-btn' onClick={handleOpenTourForm} >{websiteData?.installationBlockButtonTitle}</Button> : null}
                                        {/* </NavHashLink> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}

                {(websiteData?.extraItems?.length > 0) ||
                    (websiteData?.extrasBlockTitle) ||
                    (websiteData?.extrasBlockShortDescription) ? <>
                    <div className='approach-blk-wrp f-wrp' id={`${websiteData?.extrasBlockId ? websiteData?.extrasBlockId : ''}`}>
                        <div className='container'>
                            <div className='approach-sec-header f-wrp'>
                                <h1>{websiteData?.extrasBlockTitle}</h1>
                                <p>{websiteData?.extrasBlockShortDescription}</p>
                                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                            </div>
                            {(websiteData?.extraItems?.length > 0) ? <><Approach facilityData={websiteData?.extraItems || []} /></> : null}
                        </div>
                    </div>
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}

                {(websiteData?.contactUsBlockTitle) ||
                    (websiteData?.contactUsBlockShortDescription) ||
                    (websiteData?.contactUsBlockLicenseNumber) ||
                    (websiteData?.contactUsBlockAddress) ||
                    (websiteData?.contactUsBlockEmail) ||
                    (websiteData?.contactUsBlockPhone) ||
                    (websiteData?.contactUsBlockFbUrl) ||
                    (websiteData?.contactUsBlockWhatsappUrl) ||
                    (websiteData?.contactUsImage) ||
                    (websiteData?.contactUsBlockInstagramUrl) ? <>
                    <div className='info-blk-wrp f-wrp' id={`${websiteData?.contactUsBlockId ? websiteData?.contactUsBlockId : ''}`}>
                        <div className='container'>
                            <div className='info-sec-blk f-wrp'>
                                <div className='info-con'>
                                    <div className='f-wrp'>
                                        <h1>{websiteData?.contactUsBlockTitle}</h1>
                                        <p>{websiteData?.contactUsBlockShortDescription}</p>
                                        <ul>
                                            {websiteData?.contactUsBlockLicenseNumber && <li><span>{SVGIcons.LicenseIcon()} </span>{websiteData?.contactUsBlockLicenseNumber}</li>}
                                            {websiteData?.contactUsBlockAddress && <li><span>{SVGIcons.PinIcon()} </span>{websiteData?.contactUsBlockAddress}</li>}
                                            {websiteData?.contactUsBlockEmail && <li><a href={`mailto:${websiteData?.contactUsBlockEmail}`}><span>{SVGIcons.MailIcon()} </span>{websiteData?.contactUsBlockEmail}</a></li>}
                                            {websiteData?.contactUsBlockPhone && <li><span>{SVGIcons.PhoneIcon()} </span><a href={`tel:${websiteData?.contactUsBlockPhone}`}>{websiteData?.contactUsBlockPhone}</a></li>}
                                        </ul>

                                        <ul className='social-media-list'>
                                            {websiteData?.contactUsBlockFbUrl && <li>
                                                <Button onClick={() => {
                                                    window.open(`${websiteData?.contactUsBlockFbUrl}`, '_blank')
                                                }} className='social-btn'><img src={AppIcons.fb} alt='Facebook' /></Button>
                                            </li>}
                                            {websiteData?.contactUsBlockWhatsappUrl && <li>
                                                <Button onClick={() => {
                                                    window.open(`${websiteData?.contactUsBlockWhatsappUrl}`, '_blank')
                                                }} className='social-btn'><img src={AppIcons.whatsapp} alt='Whatsapp' /></Button>
                                            </li>}
                                            {websiteData?.contactUsBlockInstagramUrl && <li>
                                                <Button onClick={() => {
                                                    window.open(`${websiteData?.contactUsBlockInstagramUrl}`, '_blank')
                                                }} className='social-btn'><img src={AppIcons.insta} alt='Instagram' /></Button>
                                            </li>}
                                        </ul>
                                        <Button className='sqr-btn' onClick={handleOpenContactForm} >{translateThis('contactUs')}</Button>

                                    </div>
                                </div>
                                {websiteData?.contactUsImage ? <div className='info-img'>
                                    <span><S3ImageViewerWithFallback key={`file-${websiteData?.contactUsImage}`} fileKey={websiteData?.contactUsImage} /></span>
                                </div> : null}
                            </div>
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />  </> : null}

                {(websiteData?.ourTeachersBlockTitle) ||
                    (websiteData?.ourTeachersBlockShortDescription) ||
                    (websiteData?.teachers?.length > 0) ? <>
                    <div className='teachers-blk-wrp f-wrp' id={`${websiteData?.ourTeachersBlockId ? websiteData?.ourTeachersBlockId : ''}`}>
                        <div className='container'>
                            <div className='teachers-sec-header f-wrp'>
                                <h1>{websiteData?.ourTeachersBlockTitle}</h1>
                                <p>{websiteData?.ourTeachersBlockShortDescription}</p>
                                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                            </div>
                            <Teachers facilityData={websiteData?.teachers || []} />
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} /> </> : null}


                {(websiteData?.ourPaymentBlockTitle) ||
                    (websiteData?.ourPaymentBlockDescription) ||
                    (websiteData?.ourPaymentBlockButtonTitle) ? <>
                    <div className='payment-blk-wrp f-wrp' id={`${websiteData?.ourPaymentBlockId ? websiteData?.ourPaymentBlockId : ''}`}>
                        <div className='container'>
                            <div className='payment-sec-blk f-wrp'>

                                {websiteData?.paymentSideImage ? <div className='payment-img'>
                                    <span><S3ImageViewerWithFallback key={`file-${websiteData?.paymentSideImage}`} fileKey={websiteData?.paymentSideImage} /></span>
                                </div> : null}
                                <div className='payment-con'>
                                    <div className='f-wrp'>
                                        <h1>{websiteData?.ourPaymentBlockTitle}</h1>
                                        <p>{websiteData?.ourPaymentBlockDescription} </p>
                                        {websiteData?.ourPaymentBlockButtonTitle ? <Button onClick={() => {
                                            window.location.href = `/${currentFacilityURL}/my-portal`
                                        }} className='sqr-btn'>{websiteData?.ourPaymentBlockButtonTitle}</Button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} /> </> : null}

                {(websiteData?.ourTestimonialBlockTitle) ||
                    (websiteData?.ourTestimonialBlockShortDescription) ||
                    (websiteData?.testimonials?.length > 0) ? <>
                    <div className='testimonial-blk-wrp f-wrp' id={`${websiteData?.ourTestimonialBlockId ? websiteData?.ourTestimonialBlockId : ''}`}>
                        <div className='container'>
                            <div className='testimonial-sec-header f-wrp'>
                                <h1>{websiteData?.ourTestimonialBlockTitle}</h1>
                                <p>{websiteData?.ourTestimonialBlockShortDescription}</p>
                                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                            </div>
                            <Testimonial facilityData={websiteData?.testimonials || []} />
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} /> </> : null}



            </div>

            <Modal
                className='ppContact-popup'
                open={openContactForm}
                onClose={handleCloseContactForm}
                aria-labelledby="ppContact-popup"
                aria-describedby="ppContact-popup"
            >
                <div className='pp-contact-form f-wrp'>
                    <div className='pp-contact-head'>
                        <p>{translateThis('Contact us')} 💬</p>
                        <Button onClick={handleCloseContactForm}>{SVGIcons.CrossIcon()}</Button>
                    </div>

                    <ContactForm onSuccess={handleCloseContactForm} />
                </div>
            </Modal>

            <Modal
                className='ppContact-popup'
                open={openTourForm}
                onClose={handleCloseTourForm}
                aria-labelledby="ppContact-popup"
                aria-describedby="ppContact-popup"
            >
                <div className='pp-contact-form f-wrp'>
                    <div className='pp-contact-head'>
                        <p>{translateThis('Our installation virtual tour')} 🎥</p>
                        <Button onClick={handleCloseTourForm}>{SVGIcons.CrossIcon()}</Button>
                    </div>

                    <ContactForm onSuccess={handleCloseTourForm} defaultComment='Hello, I’m interested in the virtual tour.' />
                </div>
            </Modal>


        </AppLayout>
    )
}
TemplatesOne.propTypes = {
    facilityData: PropTypes.any,
    language: PropTypes.string
}
export default TemplatesOne;