import dayjs from 'dayjs';

import { TextField } from "@mui/material";
import { useEffect, useState } from 'react';
import { DATE_TIME_EXTENDED_FORMAT } from '../../../../constants';
import { translateThis } from '../../../../helpers/language.helper';

export const TimerInput = () => {

    const [time, setTime] = useState(dayjs());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(dayjs());
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    return (
        <TextField
            type='text'
            label={translateThis('Submission date and time')}
            value={dayjs(time).format(DATE_TIME_EXTENDED_FORMAT)}
            disabled
        />
    )

}