import React from "react";
import PropTypes from 'prop-types';
import { translateThis } from '../../../../helpers/language.helper';
import './teachers.scss';
import S3ImageViewerWithFallback from "../../../../components/awsS3ImageViewer/ImgViewerWithFallback";



function Teachers({ facilityData }) {
  return (
    <>
      <div className="TemplatesOne-teachers-wrp f-wrp">
        <div className="row">
          {facilityData.map((element, key) => (
            <div className={`${(facilityData.length === 1) ? 'col-lg-8 col-md-12 col-sm-12 col-xs-12 single-box' : 'col-lg-4 col-md-6 col-sm-12 col-xs-12'}`} key={key}>
              <div className="each-teachers-sec f-wrp ">
                <div className="teachers-img-sec f-wrp">
                  <span className='fee-sec-icon'>
                    <S3ImageViewerWithFallback key={`file-${element?.image.fileKey}`} fileKey={element?.image.fileKey} />
                  </span>
                  <div className="title-box f-wrp">
                    <div className="f-wrp">
                      <h2>{translateThis(element.title)}</h2>
                      <p>{translateThis(element.designation)}</p>
                    </div>
                  </div>
                </div>
                {element.description ? <div className="teachers-con-sec f-wrp">
                  <p>{translateThis(element.description)}</p>
                </div> : null}

              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
Teachers.propTypes = {
  facilityData: PropTypes.any
}
export default Teachers;